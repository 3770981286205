import React, { useEffect } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import HeaderExpanded from '../components/HeaderExpanded/HeaderExpanded';
import '../styles/sustainability.scss';
import { Helmet } from 'react-helmet';

function Sustainability() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* Add meta tags inside here. */}
      <Helmet>
        <title>HortPlus | Sustainability</title>
        <meta
          name='description'
          content='We believe in sustainable operation and building solutions
          that enable better resource use'
        />
      </Helmet>
      <HeaderExpanded
        title='Sustainability Focused &amp; Trusted by Growers '
        image={
          process.env.GATSBY_S3_BUCKET_ASSETS + 'images/sustainability.jpg'
        }
      />
      <Container className='my-5'>
        <Row>
          <Col xs={12} sm={7}>
            <p className='hp-font-28'>
              HortPlus delivers solutions that are time-sensitive and critical
              to grower’s operations. We understand the intricacies of the
              primary sector and the challenges that are faced throughout the
              season. We believe in sustainable operation and building solutions
              that enable better resource use.
            </p>
            <p>
              HortPlus pioneered the development of digital decision support
              tools as the New Zealand Apple industry moved from calendar-based
              chemical spraying to a "Futures" Integrated Productivity Programme
              in the 2000s. The goal of this programme was to reduce sprays and
              residue levels and minimise environmental impact. Since then we
              have worked with a number of industries and firms to help them
              along this journey. With increasing market access compliance
              regulations and residue limits this has become a key part of
              industry programmes.
            </p>
            <p>
              For over 15 years HortPlus has continued to provide decision
              support tools that are used by growers and firms throughout New
              Zealand and we value our business relationships and the success of
              our clients over the long term.
            </p>
          </Col>
          <Col xs={12} sm={5} className='d-block text-center'>
            <Image
              src={
                process.env.GATSBY_S3_BUCKET_ASSETS + 'brands/nzgap_logo.png'
              }
              alt='NZ GAP'
              width='300'
              className='p-3'
              fluid
            />

            <Image
              src={
                process.env.GATSBY_S3_BUCKET_ASSETS + 'brands/growsafe_logo.png'
              }
              alt='NZ GrowSafe'
              width='300'
              className='p-3'
              fluid
            />
            <Image
              src={
                process.env.GATSBY_S3_BUCKET_ASSETS +
                'images/hortplus_digital_tools_in_the_field.jpg'
              }
              alt='Digital tools in the field'
              fluid
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Sustainability;
